<template>
  <div>
    <div
      class="modal fade"
      :id="identificador"
      tabindex="-1"
      :aria-labelledby="identificador"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="cabecalho modal-header">
            <slot name="cabecalho">Atenção!</slot>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <slot name="corpo">Deseja continuar?</slot>
          </div>
          <div class="modal-footer">
            <div class="row g-0 w-100">
              <div class="col-6 pe-2">
                <button
                  class="btn botao-secundario"
                  data-bs-dismiss="modal"
                  @click="aoCancelar"
                >
                  NÃO
                </button>
              </div>
              <div class="col-6 ps-2">
                <button
                  class="btn botao-primario"
                  data-bs-dismiss="modal"
                  @click="aoContinuar"
                >
                  SIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirmarAcao",
  components: {},
  props: {
    identificador: {
      type: String,
      required: true,
    },
    aoContinuar: {
      type: Function,
      required: true,
    },
    aoCancelar: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.modal-header {
  border-bottom: none;
}
.modal-content {
  border: none;
}
.modal-footer {
  border-top: none;
}
.cabecalho {
  color: var(--cor-tema);
  font-weight: 600;
  font-size: 18px;
}
.modal-footer button {
  width: 100%;
  font-weight: 600;
}
.botao-primario {
  color: var(--cor-tema-fundo);
  background-color: var(--cor-tema-escura);
}
.botao-primario:hover {
  opacity: 0.9;
}
.botao-secundario {
  color: var(--cor-tema-escura);
  border: 1px solid var(--cor-tema-escura);
  background-color: var(--cor-tema-fundo);
}
.botao-secundario:hover {
  opacity: 0.9;
}
</style>
