<template>
  <input v-maska="mascara" :value="valorMascarado" @maska="aoMascarar" />
</template>

<script>
import { mask, tokens } from "maska";

export default {
  name: "CampoComMascara",
  components: {},
  props: {
    mascara: {
      type: [String, Array],
      default: "",
    },
    modelValue: String,
  },
  data() {
    return {
      valorBruto: this.modelValue,
    };
  },
  methods: {
    aoMascarar(evento) {
      this.valorBruto = evento.target.dataset.maskRawValue;
    },
  },
  computed: {
    valorMascarado() {
      return mask(this.valorBruto ?? "", this.mascara, tokens);
    },
  },
  emits: ["update:modelValue"],
  watch: {
    valorBruto(novoValor) {
      this.$emit("update:modelValue", novoValor);
    },
  },
};
</script>