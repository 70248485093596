<template>
  <CampoComMascara
    type="tel"
    mascara="(##) #####-####"
    :modelValue="valor"
    @update:modelValue="$emit('update:modelValue', `+55${$event}`)"
  />
</template>

<script>
import CampoComMascara from "@/components/shared/campos/CampoComMascara.vue";

export default {
  name: "CampoCelular",
  components: {
    CampoComMascara,
  },
  data() {
    return {
      valor: this.modelValue?.substring(3),
    };
  },
  props: {
    modelValue: String,
  },
  emits: ["update:modelValue"],
};
</script>
