<template>
  <button class="btn botao-cancelar shadow-sm" @click="aoClicar">
    {{ legenda }}
  </button>
</template>

<script>
export default {
  name: "BotaoCancelar",
  props: {
    legenda: {
      type: String,
      required: true,
    },
    aoClicar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.botao-cancelar {
  display: block;
  width: 100%;
  margin: 16px auto;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 1.2em;
  color: var(--cor-tema-escura);
  border: 1px solid var(--cor-tema-escura);
  background-color: var(--cor-tema-fundo);
}
.botao-cancelar:hover {
  opacity: 0.9;
}
</style>
