<template>
  <div class="d-flex flex-row align-items-center p-3">
    <div
      class="d-flex flex-column align-items-center"
      v-for="(legenda, indice) in fases"
      :key="indice"
    >
      <span class="legenda">{{ indice + 1 }} - {{ legenda }}</span>

      <div class="d-flex flex-row align-items-center">
        <div
          class="linha"
          :class="{ preenchida: indice < fase, invisivel: indice == 0 }"
        ></div>
        <div class="circulo" :class="{ preenchido: indice < fase }"></div>
        <div
          class="linha"
          :class="{
            preenchida: indice < fase - 1,
            invisivel: indice == fases.length - 1,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressoPedido",
  props: {
    fase: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    fases() {
      return ["Escolher produtos", "Dados para contato", "Enviar pedido"];
    },
  },
};
</script>

<style scoped>
.legenda {
  font-size: 0.7em;
}
.circulo {
  width: 20px;
  height: 20px;
  border: var(--cor-tema-escura) 2px solid;
  border-radius: 20px;
}
.circulo.preenchido {
  background: var(--cor-tema-escura);
}
.linha {
  width: 75px;
  border-top: #ccc 2px dotted;
}
.linha.preenchida {
  border-top-color: var(--cor-tema-escura);
  border-top-style: solid;
}
.linha.invisivel {
  visibility: hidden;
}
</style>