<template>
  <div>
    <div class="cabecalho d-flex flex-row align-items-center">
      <BotaoVoltar />
      <h1 class="my-0 mx-2">{{ titulo }}</h1>
    </div>

    <div class="d-flex flex-column align-items-center my-4">
      <ProgressoPedido :fase="fase" />
    </div>

    <div class="meu-pedido mb-5">
      <form action="" class="px-3">
        <div
          class="campo mb-3"
          :class="{ 'campo-invalido': v$.formulario.cliente.$error }"
        >
          <label
            for="formGroupExampleInput"
            class="small"
            :class="{
              obrigatorio: v$.formulario.cliente.required,
              opcional: !v$.formulario.cliente.required,
            }"
            >Cliente</label
          >
          <input
            type="text"
            class="form-control error"
            id="formGroupExampleInput"
            v-model="formulario.cliente"
            :placeholder="somenteLeitura ? '-' : 'Preencha com seu nome'"
            :disabled="somenteLeitura"
          />
        </div>
        <div
          class="campo mb-3"
          :class="{ 'campo-invalido': v$.formulario.contato.$error }"
        >
          <label
            for="formGroupExampleInput2"
            class="small"
            :class="{
              obrigatorio: v$.formulario.contato.required,
              opcional: !v$.formulario.contato.required,
            }"
            >Whatsapp/celular</label
          >
          <CampoCelular
            class="form-control"
            id="formGroupExampleInput2"
            v-model="formulario.contato"
            :placeholder="
              somenteLeitura ? '-' : 'Preencha com seu número de Whatsapp'
            "
            :disabled="somenteLeitura"
          />
        </div>
        <div
          class="campo mb-3"
          :class="{ 'campo-invalido': v$.formulario.email.$error }"
        >
          <label
            for="formGroupExampleInput3"
            class="small"
            :class="{
              obrigatorio: v$.formulario.email.required,
              opcional: !v$.formulario.email.required,
            }"
            >E-mail</label
          >
          <input
            type="email"
            class="form-control"
            id="formGroupExampleInput3"
            v-model="formulario.email"
            :placeholder="somenteLeitura ? '-' : 'Preencha com seu e-mail'"
            :disabled="somenteLeitura"
          />
        </div>
        <div
          class="campo mb-3"
          :class="{ 'campo-invalido': v$.formulario.endereco.$error }"
        >
          <label
            for="formGroupExampleInput3"
            class="small"
            :class="{
              obrigatorio: v$.formulario.endereco.required,
              opcional: !v$.formulario.endereco.required,
            }"
            >Endereço</label
          >
          <input
            type="text"
            class="form-control"
            id="formGroupExampleInput3"
            v-model="formulario.endereco"
            :placeholder="somenteLeitura ? '-' : 'Preencha com seu endereço'"
            :disabled="somenteLeitura"
          />
        </div>
        <!--
        <div class="campo mb-3">
          <label
            for="formGroupExampleInput2"
            class="small"
            :class="{
              obrigatorio: v$.formulario.entrega.required,
              opcional: !v$.formulario.entrega.required,
            }"
            >Forma de entrega</label
          >
          <select
            class="form-control form-select"
            id="inlineFormSelectPref"
            :disabled="somenteLeitura"
          >
            <option selected>Selecione...</option>
            <option value="1">Entregar no endereço (a combinar)</option>
            <option value="2">Retirar na loja (a combinar)</option>
          </select>
        </div>
        -->
        <div
          class="campo mb-3"
          :class="{ 'campo-invalido': v$.formulario.comentario.$error }"
        >
          <label
            for="formGroupExampleInput2"
            class="small"
            :class="{
              obrigatorio: v$.formulario.comentario.required,
              opcional: !v$.formulario.comentario.required,
            }"
            >Comentário</label
          >
          <textarea
            class="form-control"
            id="formGroupExampleInput2"
            rows="5"
            maxlength="300"
            v-model="formulario.comentario"
            :placeholder="somenteLeitura ? '-' : 'Preencha com seu comentário'"
            :disabled="somenteLeitura"
          ></textarea>
          <p class="observacao-campo my-1" v-if="!somenteLeitura">
            {{ formulario.comentario.length }}/300
          </p>
        </div>
      </form>

      <div class="itens-venda px-3">
        <h5>Itens da venda</h5>
        <div>
          <div class="row mb-2" v-for="item in pedido.itens" :key="item.id">
            <div class="col-7">{{ item.nome }}</div>
            <div class="col-2 text-end">{{ item.quantidade }}</div>
            <div class="col-3 fw-bold text-end">
              <Dinheiro :valor="item.valor" />
            </div>
          </div>
        </div>
      </div>
      <hr class="mx-3" />
      <ValorTotalPedido class="mx-3" />
    </div>

    <div class="row px-3" v-if="somenteLeitura">
      <div class="col">
        <BotaoPrimario
          :legenda="`Voltar para a loja ${loja.nome}`"
          :ao-clicar="$router.back"
        />
      </div>
    </div>
    <div class="row px-3" v-else>
      <div class="col-6 pe-2">
        <BotaoCancelar legenda="Cancelar" :ao-clicar="cancelarPedido" />
      </div>

      <div class="col-6 ps-2">
        <BotaoPrimario legenda="Enviar pedido" :ao-clicar="validarPedido" />
      </div>

      <ModalConfirmarAcao
        identificador="modal-confirmar-pedido"
        :ao-continuar="processaPedido"
      >
        <template v-slot:cabecalho>Confirmar pedido?</template>
        <template v-slot:corpo>
          <div class="small">
            A confirmação de pedido e pagamento serão feitas diretamente com a
            loja
            <strong>{{ loja.nome }}</strong
            >, através de seu contato:
            <br />
            <span class="dado-pessoal">{{ formulario.email }}</span>
            <br />
            <span class="dado-pessoal">{{ formulario.contato }}</span>
            <hr />
            <strong>Estoque sujeito à disponibilidade.</strong>
          </div>
        </template>
      </ModalConfirmarAcao>
    </div>
  </div>
</template>

<script>
import ValorTotalPedido from "@/components/loja/ValorTotalPedido.vue";
import ProgressoPedido from "@/components/loja/ProgressoPedido.vue";

import BotaoVoltar from "@/components/shared/botoes/BotaoVoltar.vue";
import Dinheiro from "@/components/shared/formatadores/Dinheiro.vue";
import CampoCelular from "@/components/shared/campos/CampoCelular.vue";
import BotaoPrimario from "@/components/shared/botoes/BotaoPrimario.vue";
import BotaoCancelar from "@/components/shared/botoes/BotaoCancelar.vue";
import ModalConfirmarAcao from "@/components/shared/modais/ModalConfirmarAcao.vue";

import { mapGetters, mapState } from "vuex";
import { required, minLength, maxLength, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { Modal } from "bootstrap";

export default {
  name: "FormularioPedido",
  components: {
    ValorTotalPedido,
    ProgressoPedido,
    BotaoVoltar,
    Dinheiro,
    CampoCelular,
    BotaoPrimario,
    BotaoCancelar,
    ModalConfirmarAcao,
  },
  props: {
    titulo: {
      type: String,
      required: true,
    },
    somenteLeitura: {
      type: Boolean,
      default: () => false,
    },
    submete: {
      type: Function,
    },
  },
  created() {
    if (this.somenteLeitura) {
      this.formulario = {
        cliente: this.pedido.cliente,
        contato: this.pedido.contato,
        email: this.pedido.email,
        endereco: this.pedido.endereco,
        comentario: this.pedido.comentario,
      };
      this.fase = 3;
    }
  },
  data() {
    return {
      fase: 1,
      formulario: {
        cliente: "",
        contato: "",
        email: "",
        endereco: "",
        comentario: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formulario: {
        cliente: { required },
        contato: { required, min: minLength(14), max: maxLength(14) },
        email: { required, email },
        endereco: {},
        comentario: {},
      },
    };
  },
  methods: {
    async validarPedido() {
      const formularioValido = await this.v$.$validate();
      if (formularioValido) {
        this.fase = 2;
        const modal = new Modal(
          document.getElementById("modal-confirmar-pedido")
        );
        modal.show();
      }
    },
    processaPedido() {
      this.$gtag.event("envia_pedido", { valor: this.total });
      this.submete(this.formulario);
    },
    cancelarPedido() {
      this.$router.back();
    },
  },
  computed: {
    ...mapState(["pedido"]),
    ...mapState(["loja"]),
    ...mapGetters(["total"]),
  },
};
</script>

<style scoped>
.cabecalho {
  background-color: var(--cor-tema);
  color: var(--cor-tema-fundo);
  padding: 1rem 1rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.cabecalho h1 {
  font-size: 1.3rem;
  font-weight: 600;
}
.cabecalho .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 0.9;
}
.meu-pedido {
  height: 100%;
}
.campo label,
.campo .observacao-campo {
  color: hsl(0, 0%, 60%);
}
.observacao-campo {
  font-size: 0.9em;
  text-align: right;
}
.campo .form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  padding: 0;
  background: transparent;
}
.campo .form-control:disabled {
  border: none;
  background: transparent;
}
.campo label.obrigatorio:after {
  content: "*";
  color: var(--cor-tema);
  padding-left: 0.1em;
}
.campo label.opcional:after {
  content: "(opcional)";
  color: var(--cor-tema);
  padding-left: 0.1em;
}
.campo.campo-invalido label {
  color: #dc3545;
}
.campo.campo-invalido label:after {
  color: #dc3545;
}
.campo.campo-invalido .form-control {
  border-color: #dc3545;
}
.dado-pessoal {
  color: var(--cor-tema);
}
</style>
